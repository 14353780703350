.ant-input {
  padding: 4px 11px !important;
  resize: none !important;
  line-height: 1.5714285714285714 !important;
}
.ant-input[disabled] {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-input-affix-wrapper {
  box-shadow: none !important;
}
.ant-input-affix-wrapper > input.ant-input[type='password'],
.ant-input-affix-wrapper > input.ant-input[type='text'] {
  padding: 0 !important;
}
.ant-input-affix-wrapper .anticon {
  color: #979797 !important;
}

.ant-input-lg {
  padding: 6.5px 11px !important;
  line-height: 1.5714285714285714 !important;
}

/**
 *
 */
.ant-input-number {
  box-shadow: none;
}

.ant-input-number .ant-input-number-handler-up-inner.anticon-up,
.ant-input-number .ant-input-number-handler-down-inner.anticon-down {
  color: #979797;
}

.ant-input-number-handler:hover {
  .anticon-up,
  .anticon-down {
    color: var(--primary-color-hover) !important;
  }
}
