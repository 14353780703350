@import '../mapButtons';

.VesselsMap
  #windy
  .leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-bar.leaflet-control,
.VesselsMap
  .leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-bar.leaflet-control {
  position: absolute;
  right: 17px;
  top: 122px;
  margin: 0 !important;
}

.VesselsMap
  .leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-bar.leaflet-control
  #polyline-measure-control {
  @include icomoon('\e90f');
  @extend .MapControlButtonOfGroup;

  width: 40px !important;
  height: 40px !important;
  box-shadow: none;
  border-radius: 0;
  border: none !important;
  background-color: transparent !important;
  text-indent: -9999px;
  overflow: hidden;

  &:before {
    font-size: 22px;
    line-height: 38px;
    text-indent: 0;
    display: block;
    width: 100%;
  }

  &.polyline-measure-controlOnBgColor {
    color: var(--primary-color) !important;

    ~ .polyline-measure-clearControl {
      display: block !important;
    }
  }
}

.VesselsMap
  .leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-bar.leaflet-control
  .polyline-measure-clearControl {
  @include icomoon('\e901');
  @extend .MapControlShadow;

  text-indent: -9999px;
  overflow: hidden;

  position: absolute;
  left: -41px;
  top: 0;
  width: 38px !important;
  height: 38px !important;
  color: $white !important;
  background: var(--primary-color) !important;
  border-radius: var(--border-radius-base);
  display: none !important;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--primary-color-hover) !important;
    color: $white !important;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    background-color: var(--primary-color-dark) !important;
  }

  &:before {
    font-size: 12px;
    line-height: 38px;
    text-indent: 0;
    display: block;
  }
}

/**
 *
 */
.MainMap
  #windy
  .leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-distance,
.MainMap
  .leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-distance {
  position: absolute;
  right: 8px;
  top: 44px;
  margin: 0 !important;
}

/**
 *
 */
.MainMap
  .leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-distance
  #polyline-measure-control {
  @include icomoon('\e90f');
  @extend .MapButtonColors;
  @extend .MapControlShadow;

  width: 32px !important;
  height: 36px !important;
  border-radius: 0 0 32px 32px;
  border-top: none !important;
  text-indent: -9999px;
  overflow: hidden;
  line-height: 2;

  &:before {
    font-size: 20px;
    line-height: 36px;
    text-indent: 0;
    display: block;
    width: 100%;
    color: var(--main-button-color);
    transition: all 0.2s;
  }

  &:hover:before {
    color: var(--main-button-color-hover) !important;
  }
  &:active:before {
    color: var(--main-button-color-active) !important;
  }

  &.polyline-measure-controlOnBgColor {
    color: var(--primary-color) !important;

    ~ .polyline-measure-clearControl {
      display: block !important;
    }
  }
}

/**
 *
 */
.MainMap
  .leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-distance
  .polyline-measure-clearControl {
  @include icomoon('\e901');
  @extend .MapControlShadow;

  text-indent: -9999px;
  overflow: hidden;

  position: absolute;
  left: -40px;
  top: 2px;
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  color: #fff;
  border-radius: var(--border-radius-base);
  display: none !important;

  &:not(:hover):not(:active) {
    background: var(--primary-color) !important;
  }

  &:before {
    font-size: 14px;
    line-height: 32px;
    text-indent: 0;
    display: block;
  }
}
