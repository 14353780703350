.ant-collapse-content {
  background-color: var(--body-background) !important;
}
.ant-collapse-expand-icon {
  padding-inline-end: 0 !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 12px;
}

/**/
/**/
/**/
.ant-collapse {
  > .ant-collapse-item {
    &.ant-collapse-item-active {
      > .ant-collapse-header {
        .ant-collapse-arrow {
          transform: translateY(-50%) rotate(0);
        }
      }
    }

    > .ant-collapse-header {
      //padding: 22px 40px 24px 0 !important;

      .ant-collapse-arrow {
        @include transition;
        position: absolute;
        top: 45%;
        right: -2px;
        font-size: 22px;
        font-weight: bold;
        transform: translateY(-50%) rotate(180deg);

        @supports (-webkit-touch-callout: none) {
          top: 43%;
        }
      }
    }
  }

  &.FilterCollapse {
    border: 0;
    border-bottom: 1px solid var(--button-border-color);
    padding-bottom: 16px;
    background: none;

    > .ant-collapse-item {
      border: 0;

      &.ant-collapse-item-active {
        > .ant-collapse-header {
          .icon-custom {
            transform: translateY(-50%) rotate(90deg);
          }
        }
      }

      > .ant-collapse-header {
        padding: 0 0 2px 20px !important;
        font: 700 14px/22px $font-inter;
        text-transform: uppercase;

        .icon-custom {
          left: 0;
          right: auto;
          font-size: 18px;
        }
      }
    }

    .ant-collapse-content {
      border: 0;

      > .ant-collapse-content-box {
        padding: 10px 10px 10px 10px;
      }
    }
  }

  &.ant-collapse-borderless {
    background: $white;
  }

  &.FilterCollapse--disabled {
    .ant-collapse-header {
      color: $grey-medium;
    }
  }
}
