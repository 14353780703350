.ant-tooltip {
  padding: 0 !important;
  font-size: 12px !important;
}
.ant-tooltip-inner {
  color: #fff !important;
  background-color: var(--tooltip-bg) !important;
  min-width: 30px !important;
  min-height: 30px !important;
  padding-top: 5px !important;
}
.ant-tooltip-arrow::before {
  background-color: var(--tooltip-bg) !important;
  bottom: -3px !important;
}
