.ant-message {
  z-index: 1110;
  top: calc(60px + var(--sat)) !important;
}

.ant-message-custom-content {
  display: flex;

  .anticon {
    align-items: flex-start;
    margin-top: 3px;
  }

  @include breakpoint(sm) {
    flex-direction: row;
    align-items: flex-start;

    .anticon {
      margin-top: 4px;
    }
  }
}

.ant-message-notice-wrapper {
  @include breakpoint(sm) {
    display: flex;
    justify-content: center;
  }
}

.ant-message-notice {
  @include breakpoint(sm) {
    max-width: calc(100% - 100px);
  }
}
