.ant-tabs-tab {
  background-color: var(--tabs-item-bg) !important;
  text-transform: uppercase;
}
.ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--body-background) !important;
}
.ant-tabs-content {
  display: flex;
}
.ant-tabs-nav {
  background-color: var(--body-background) !important;
}
.ant-tabs-tabpane {
  width: 100%;
}
