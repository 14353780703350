/* Common style set */
/* Todo: add app prefix to all classes, refactor project, rename to global.scss and move */

.app-container {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
}

.flex {
  display: flex;
}

.app-d-flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-img-responsive {
  max-height: 100%;
  max-width: 100%;
}

.app-pointer {
  cursor: pointer;
}

.app-error-msg {
  color: orangered !important;
}

.app-input,
.app-area {
  padding: 0.5em 0.6em;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: var(--border-radius-base);
  vertical-align: middle;
  box-sizing: border-box;
  display: block;
  margin: 0.25em 0;
  width: 100%;
}

.app-area {
  height: 4rem;
}

.app-btn-green {
  color: white;
  cursor: pointer;
  background: $color-green;

  &:hover {
    background-color: rgba($color-green, 0.85);
  }

  &[disabled] {
    background-color: rgba($color-green, 0.5);
  }
}

/* fixed ratio couple */
.app-fixed-ratio-container {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    /* this value influences the ratio */
    padding-top: 56.3%;
  }
}

.app-fixed-ratio-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.app-photo-container {
  position: relative;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

/* tables */
.stripped {
  tr:nth-child(odd) {
    background: $white;
  }

  tr:nth-child(even) {
    background: lightgrey;
  }
}

.button {
  background: $primary;
  padding: 5px 8px;
  text-align: center;
  border-radius: 2px;
  color: white;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/**/
@keyframes fadeInDelayed {
  0% {
    opacity: 0;
    max-height: 0px;
    max-width: 0px;
  }
  60% {
    opacity: 0;
    max-height: 0px;
    max-width: 0px;
  }
  61% {
    opacity: 0;
    height: 36px;
    width: 54px;
  }
  100% {
    opacity: 1;
    height: 36px;
    width: 54px;
  }
}

.app-info-table {
  width: 100%;
  table-layout: fixed;
  color: $black-dark;
  display: block;
  overflow: auto;

  th,
  td {
    padding: 8px 10px;
    vertical-align: top;
  }

  th {
    font-weight: 600;
    font-size: 18px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  tr {
    &:hover {
      th,
      td {
        background: rgba(196, 196, 196, 0.2);
      }
    }
  }

  &-head {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    text-transform: uppercase;
  }

  &-border-bottom {
    border-bottom: 1px solid #c4c4c4;

    th,
    td {
      border-bottom: 1px solid #c4c4c4;
    }
  }

  &-col-w50 {
    width: 50%;

    @include breakpoint(sm) {
      width: 35%;
    }
  }

  &-align-center {
    text-align: center;
  }

  &-align-right {
    text-align: right;
  }

  &-text-sm {
    font-style: normal;
    color: $grey-medium;
    font-size: 13px;
  }
}

.BasicFilter {
  &__banner {
    padding: 0 0 16px;
    border-bottom: 1px solid var(--button-border-color);
  }

  &__label--bold,
  &__label {
    line-height: 22px;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    margin-bottom: 8px;
    color: var(--title);

    &--bold {
      font-weight: bold;
    }
  }

  &__row {
    padding: 10px 0 14px;

    &--bordered {
      border-bottom: 1px solid rgba(196, 196, 196, 0.5);
    }

    .MultiSelect {
      @include breakpoint(sm) {
        padding: 0;
      }
    }
  }
}

.link-underlined {
  text-decoration: underline;
}
