body {
  @include isNotTouchDevice {
    scrollbar-color: $scroll-color var(--body-background);
    scrollbar-width: thin;
    scrollbar-base-color: var(--body-background);
    scrollbar-face-color: $scroll-color;
  }
}
/* width */
::-webkit-scrollbar {
  @include isNotTouchDevice {
    width: 14px;
    height: 14px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  @include isNotTouchDevice {
    background: var(--body-background);
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  @include isNotTouchDevice {
    background: $scroll-color;
    border-radius: 5px;
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @include isNotTouchDevice {
    background: $scroll-active-color;
  }
}
