.icon-custom.icon-loading {
  animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
