/**
  * Quill-Mention styles.
 */
.ql-mention-list-container {
  max-height: 250px;
  overflow: auto;
  width: 250px;
  padding: 4px 0;
  z-index: 1050;
  background-color: var(--component-background);
  border-radius: var(--border-radius-base);
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ql-mention-list-item {
  min-height: 32px;
  padding: 5px 12px;
  line-height: 1.57;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ql-mention-list-item:not([data-id]) {
  color: var(--title);
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.ql-mention-list-item[data-id] {
  color: var(--text);
  font-size: 14px;
  transition: background 0.3s ease;
  border-radius: var(--border-radius-base);

  &:hover {
    cursor: pointer;
    background-color: var(--picker-cell-disabled-bg);
  }
}

.ql-mention-loading {
  padding: 5px 12px;
  color: var(--text);
  font-size: 14px;
}

.mention {
  cursor: pointer;
  height: 24px;
  width: 65px;
  border-radius: var(--border-radius-base);
  background-color: var(--primary-color-smoke);
  padding: 3px 0;
  //margin-right: 2px;

  span {
    //margin: 0 3px;
  }
}

/**
  data-index=0 represents the Free Trial reference.
 */
.mention/*[data-id='0']*/ {
  background-color: transparent;
  width: auto;
  height: auto;
  color: var(--primary-color);

  &:hover {
    color: var(--primary-color-hover);
  }

  &:active {
    color: var(--primary-color-dark);
  }
}
