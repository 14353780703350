@import '../../../components/common/buttons/AppButton';
@import '../../../components/common/buttons/DefaultButton/DefaultButton';
@import '../../../components/common/buttons/PrimaryButton/PrimaryButton';

.ant-modal-mask {
  z-index: 1102 !important;
}
.ant-modal-wrap {
  z-index: 1103 !important;

  .Body--ipad &,
  .Body--mobile.Body--ios & {
    top: var(--sat) !important;
  }
}
.ant-modal-close {
  right: 0 !important;
  top: 0 !important;
  width: 50px !important;
  height: 50px !important;
  background: transparent !important;
}
.ant-modal-confirm-title {
  color: var(--heading-color) !important;
  font-weight: 400 !important;
}
.ant-modal-content {
  background-color: var(--body-background) !important;
  padding: 0 !important;
}
.ant-modal-title {
  font-weight: 700 !important;
}
.ant-modal-body {
  padding: 24px !important;
}

.img-crop-modal {
  .ant-modal-content {
    border-radius: var(--border-radius-base);
  }
  .ant-modal-header {
    padding: 24px 30px 0px 30px;
    margin: 0;
    background-color: var(--body-background);
  }
  .ant-modal-title {
    font-size: 22px;
    line-height: 1.3;
    font-family: $font-ttl;
    text-align: center;
    color: var(--text);
    margin: 0;
    text-transform: uppercase;
  }
  .ant-modal-close {
    right: 8px !important;
    top: 13px !important;
  }
  .ant-modal-close-icon {
    font-size: 21px;
    line-height: 1;

    &:not(:hover) {
      color: var(--text);
    }

    &:hover {
      color: var(--primary-color-hover);
    }
  }
  .ant-modal-footer {
    padding: 0px 30px 24px 30px;
    margin: 0;

    .ant-btn-default {
      @extend .AppButton;
      @extend .DefaultButton;
      @extend .DefaultButton--S;
    }

    .ant-btn-primary {
      @extend .AppButton;
      @extend .PrimaryButton;
      @extend .PrimaryButton--S;
    }
  }

  .ant-slider {
    .ant-slider-rail {
      height: 2px;
      background-color: #979797;
    }
    .ant-slider-track {
      height: 2px;
      background-color: var(--primary-color);
    }
    .ant-slider-handle {
      &:after {
        background-color: var(--primary-color);
        box-shadow: 0 0 0 2px var(--primary-color);
      }
      &:hover:after {
        box-shadow: 0 0 0 5px var(--primary-color);
      }
    }
  }
}

.img-crop-control {
  button {
    color: #979797 !important;
  }
}

.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
  color: var(--text);
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary {
  @extend .AppButton;
  @extend .PrimaryButton;
  @extend .PrimaryButton--S;
}

.ant-modal-confirm-body .anticon {
  margin-bottom: 14px;
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-error {
  .ant-modal-body {
    .ant-modal-confirm-btns {
      .ant-btn-default {
        @extend .AppButton;
        @extend .DefaultButton;
        @extend .DefaultButton--S;
      }

      .ant-btn-primary {
        @extend .AppButton;
        @extend .PrimaryButton;
        @extend .PrimaryButton--S;
      }
    }
  }
}
