/**/
#windy .leaflet-container a,
.MapControlButtonOfGroup {
  &:not(:disabled):not(.ant-btn-disabled) {
    color: var(--text);
  }

  &:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--primary-color);
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    color: var(--primary-color-hover);
  }

  .icon-custom {
    font-size: 22px;
  }
}

/**/
.MapControlButton {
  @extend .MapControlShadow;
  @extend .MapControlButtonOfGroup;

  height: 40px !important;
  text-align: center;
  border: none !important;
  border-radius: 40px;
  background-color: var(--body-background-alpha) !important;
  transition: all 0.2s !important;
}

/**
 */
.MapControlShadow {
  &:not(:disabled):not(.ant-btn-disabled) {
    box-shadow: 0px 8px 24px 0px var(--button-default-shadow);
  }
  .Body--dark &:not(:disabled):not(.ant-btn-disabled) {
    box-shadow: 0px 8px 24px 0px var(--button-light-shadow);
  }

  &:not(:disabled):not(.ant-btn-disabled):hover {
    box-shadow: 0px 8px 24px 0px var(--button-default-shadow),
      0px 8px 24px 0px var(--button-default-shadow);
  }
  .Body--dark &:not(:disabled):not(.ant-btn-disabled):hover {
    box-shadow: 0px 8px 24px 0px var(--button-light-shadow),
      0px 8px 24px 0px var(--button-light-shadow);
  }
}

/**/
.MapButtonColors {
  background-color: var(--map-button-bg);
  border: 1px solid var(--component-background);
  transition: all 0.2s;

  &:not(:disabled):not(.ant-btn-disabled):not(:hover) {
    color: var(--main-button-color);
  }
  &:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--main-button-color-hover);
    border-color: var(--component-background);
  }
  &:not(:disabled):not(.ant-btn-disabled):active {
    color: var(--main-button-color-active);
    border-color: var(--component-background);
  }
  &:disabled {
    color: var(--button-disabled-color);
  }
}
