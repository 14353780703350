.RightSideDrawer {
  &__button.IconButton {
    color: white;
    margin-right: 8px;
    transform: rotate(180deg);
  }

  &__header {
    background: $black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 10px 11px;

    h6 {
      color: $white !important;
      font-weight: 600 !important;
      margin-bottom: 0;
    }
  }

  &__main {
    padding-top: var(--sat) !important;

    .Body--ipad & {
      padding-top: 0 !important;
    }

    .ant-drawer-body {
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .ant-switch-checked {
      background-color: var(--primary-color);
    }
  }

  &__content {
    padding: 0 8px 1rem 10px;
    flex: auto;
    overflow-y: scroll;
    overflow-x: hidden;

    .ant-tabs-content-holder {
      padding-top: 20px;
      background-color: var(--component-background);
    }

    div.ant-tabs-nav {
      width: 100%;
      margin-bottom: 0;

      &-wrap {
        padding: 10px 10px 0;
      }

      > div {
        display: flex;

        .ant-tabs-tab {
          flex-grow: 1;
          text-align: center;
          background-color: var(--body-background);
          border-bottom: 1px solid var(--button-border-color) !important;

          &-active {
            background-color: var(--component-background);
            border-bottom-color: var(--component-background) !important;
          }
        }
      }
    }
  }

  /**/
  .ant-drawer-content {
    overflow: visible;
  }
  .ant-drawer-content-wrapper {
    width: 256px !important;
    top: var(--header-height);
    height: calc(100% - var(--header-height)) !important;

    .Body--ipad & {
      top: calc(var(--header-height) + var(--sat));
      height: calc(100% - var(--header-height) - var(--sat)) !important;
    }

    .Body--ipad.Body--fullscreen & {
      top: var(--sat);
      height: 100% !important;
    }
  }
}
