@import '../mapEntities';

/* Windy */
.VesselsMap.windy #windy #embed-zoom {
  top: 0;
  left: 0;
  right: auto;
  z-index: 1000;
  width: 40px;
  transform: scale(1) !important;
  background-color: var(--body-background-alpha) !important;
  box-shadow: 0px 8px 24px 0px var(--button-default-shadow) !important;

  .zoom-ctrl {
    @extend .MapControlButtonOfGroup;

    background-color: transparent !important;
    color: var(--title);
    border: 0 !important;
    border-radius: 0 !important;
    height: 44px !important;
    width: 40px !important;
    font-weight: 400;
    font-size: 18px;
    line-height: 43px !important;
    transition: all 0.2s !important;
    margin-bottom: 0 !important;

    &:disabled {
      color: var(--button-disabled-color) !important;
    }
    &:hover {
      color: var(--primary-color) !important;
    }
  }

  .zoom-plus {
    border-bottom: 1px solid rgba(196, 196, 196, 0.3) !important;
  }
}

/* */
.VesselsMap #embed-zoom,
/*.VesselsMap*/
  .leaflet-control-container
  .leaflet-top.leaflet-left
  .leaflet-bar.leaflet-control.leaflet-control-zoom {
  @extend .MapControlShadow;

  margin: 16px 0 0 24px;
  user-select: none;
  border: none;
  border-radius: 40px !important;
  background-color: var(--body-background-alpha);

  .Body--mobile & {
    margin: 8px 0 0 8px;
  }
  .Body--fullscreen:not(.Body--touch) & {
    margin: 76px 0 0 24px !important;
  }

  @media (orientation: landscape) {
    .Body--mobile & {
      margin: 8px 0 0 24px;
    }
    .Body--mobile.Body--fullscreen & {
      margin: 80px 0 0 8px;
    }
    .Body--mobile.Body--ios.Body--fullscreen & {
      margin: 68px 0 0 calc(var(--sal) - 5px) !important;
    }
    .Body--ipad.Body--fullscreen & {
      margin: 80px 0 0 24px !important;
    }
  }

  @media (orientation: portrait) {
    .Body--mobile.Body--fullscreen & {
      margin: 102px 0 0 8px !important;
    }
    .Body--ipad.Body--fullscreen &,
    .Body--touch.Body--fullscreen:not(.Body--mobile) & {
      margin: 107px 0 0 8px !important;
    }
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    @extend .MapControlButtonOfGroup;

    background-color: transparent !important;
    border: 0 !important;
    height: 43px !important;
    width: 40px !important;
    font-weight: 400;
    font-size: 26px;
    line-height: 43px !important;
    transition: all 0.2s !important;

    &:disabled {
      color: var(--button-disabled-color) !important;
    }
  }

  .leaflet-control-zoom-in {
    border-bottom: 1px solid rgba(196, 196, 196, 0.3) !important;
  }
}

/**
 *
 */
.MainMap.windy #windy {
  .leaflet-right .leaflet-control {
    margin-right: 0px;
    float: none;
  }

  .leaflet-top .leaflet-control {
    margin-top: 0px;
  }

  .leaflet-top.leaflet-right {
    height: 0;
  }

  #map-container .leaflet-control-container {
    display: block;
  }

  .leaflet-control-zoom.leaflet-bar.leaflet-control {
    position: absolute;
    @extend .MapControlShadow;
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    @extend .MapButtonColors;

    height: 33px !important;
    width: 32px !important;
    font-weight: 400;
    font-size: 28px;
    line-height: 26px !important;
    transition: all 0.2s !important;

    &.leaflet-disabled {
      color: var(--button-disabled-color);
    }
  }

  .leaflet-control-zoom-in {
    border-bottom: none !important;
    border-radius: 32px 32px 0 0;
  }

  .leaflet-control-zoom-out {
    border-radius: 0 0 32px 32px;
  }

  a.leaflet-control-zoom-in.leaflet-disabled,
  a.leaflet-control-zoom-out.leaflet-disabled {
    color: var(--button-disabled-color) !important;
  }
}

/**
 *
 */
.MainMap #embed-zoom {
  display: none !important;
}

/**
 *
 */
.HistoricalCatch .leaflet-control-zoom.leaflet-bar.leaflet-control,
.HistoricalTrack .leaflet-control-zoom.leaflet-bar.leaflet-control,
.MainMap .leaflet-control-zoom.leaflet-bar.leaflet-control,
.TrackPlayer .leaflet-control-zoom.leaflet-bar.leaflet-control {
  @extend .MapControlShadow;

  position: absolute;
  right: 8px;
  margin: 0;
  user-select: none;
  border-radius: 32px !important;
  overflow: hidden;

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    @extend .MapButtonColors;

    height: 33px !important;
    width: 32px !important;
    font-weight: 400;
    font-size: 28px;
    line-height: 26px !important;

    &.leaflet-disabled {
      color: var(--button-disabled-color);
    }
  }

  .leaflet-control-zoom-in {
    border-bottom: none !important;
    border-radius: 32px 32px 0 0;
  }

  .leaflet-control-zoom-out {
    border-radius: 0 0 32px 32px;
  }

  a.leaflet-control-zoom-in.leaflet-disabled,
  a.leaflet-control-zoom-out.leaflet-disabled {
    color: var(--button-disabled-color) !important;
  }
}

/**
 *
 */
.MainMap .leaflet-control-zoom.leaflet-bar.leaflet-control {
  top: 86px;
}
.Body--mobile .MainMap .leaflet-control-zoom.leaflet-bar.leaflet-control {
  display: none;
}

/**
 *
 */
.HistoricalCatch .leaflet-control-zoom.leaflet-bar.leaflet-control,
.HistoricalTrack .leaflet-control-zoom.leaflet-bar.leaflet-control,
.TrackPlayer .leaflet-control-zoom.leaflet-bar.leaflet-control {
  top: 8px;
}

.HistoricalTrack
  .HistoricalTrack__container--expanded
  .leaflet-control-zoom.leaflet-bar.leaflet-control {
  right: 366px;
  animation: fadeIn 0.8s;
}

.Body--fullscreen
  .HistoricalTrack
  .HistoricalTrack__container--expanded
  .leaflet-control-zoom.leaflet-bar.leaflet-control {
  right: 8px;
  animation: fadeIn 0.8s;
}
