.app-text-lowercase {
  text-transform: lowercase !important;
}

.app-text-uppercase {
  text-transform: uppercase !important;
}

.app-text-capitalize {
  text-transform: capitalize !important;
}
