.ant-table {
  th {
    font-size: 14px !important;
  }

  @include breakpoint(xl) {
    th {
      font-size: 12px !important;
    }
    td {
      font-size: 13px !important;
    }
  }
}
.ant-table-footer {
  background: var(--body-background) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  @include breakpoint(md-up) {
    min-height: 100px;
  }
}
.ant-table-cell {
  padding: 4px 9px !important;
  line-height: 1;
  font-family: $font-inter;
  font-size: 15px;
  font-weight: 400;
  height: 40px;

  &:first-child {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.15);
    background: var(--component-background);
  }

  &.ant-table-column-sort {
    background-color: var(--button-border-color);
  }
}
.ant-table-thead .ant-table-cell {
  color: var(--title) !important;
  background-color: var(--component-background-alpha) !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500 !important;
}
.ant-table-thead > tr > th {
  color: var(--heading-color) !important;
  border-bottom: 1px solid var(--button-border-color) !important;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--button-border-color) !important;
  border-top: none !important;
}
.ant-table-tbody > tr.ant-table-measure-row > td {
  border-bottom: none !important;
}
.ant-table-column-sorter {
  color: #bfbfbf !important;
}
.ant-table-column-sorters {
  font-weight: 600;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
