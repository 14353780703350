.app-page-narrow {
  position: relative;
  padding: 24px var(--content-side-padding) 32px;
  margin: 0 auto;
  max-width: 1150px;
  height: auto !important;
}

.app-page-text {
  @extend .app-page-narrow;
  max-width: 844px;
  height: auto !important;
  width: 100%;
}

.app-page-wide {
  position: relative;
  height: 100%;
  padding: 0 0 0 20px;

  @include breakpoint(md) {
    padding: 0 3px 0 20px;
  }

  @include breakpoint(xxs) {
    padding: 0 20px 0 20px;
  }
}

/**/
.app-page-map {
  position: relative;
  height: 100%;
}

.app-page-title {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0 0 24px 0;

  @include breakpoint(md) {
    font-size: 24px;
  }
}

/**/
.InformationPage {
  max-width: 740px;

  h2 {
    margin-bottom: 1.5rem;
  }

  ul,
  ol {
    li {
      margin-bottom: 1.5rem;
    }
  }
}
