@font-face {
  font-family: 'Inter';
  src: url('../../public/assets/font/Inter-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../public/assets/font/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../public/assets/font/Inter-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TitilliumWeb';
  src: url('../../public/assets/font/TitilliumWeb-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TitilliumWeb';
  src: url('../../public/assets/font/TitilliumWeb-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TitilliumWeb';
  src: url('../../public/assets/font/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
