.app-btn-min-w-170 {
  min-width: 170px;
}

.app-btn-w-100 {
  width: 100%;
}

.app-no-opacity-on-hover {
  &:hover {
    opacity: 1;
  }
}

.app-btn-reset {
  padding: 0 !important;
  border: 0 !important;
}
