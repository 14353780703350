.ant-select-arrow {
  color: #979797 !important;
}
.ant-select-dropdown {
  padding: 4px 0 !important;
}
.ant-select-selection-placeholder {
  font-size: 16px;
  color: var(--switch-bg) !important;
}
.ant-select-selector {
  box-shadow: none !important;
}
.ant-select-multiple .ant-select-selection-item-remove:not(:hover) {
  color: #979797 !important;
}
.ant-select-multiple .ant-select-selection-item {
  background-color: var(--select-item-hover-bg);
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #979797 !important;
}

.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled):not(
    .ant-select-item-option-selected
  ) {
  background-color: var(--select-item-hover-bg);
}

.ant-empty {
  .ant-empty-description {
    color: var(--button-disabled-color);
  }
}
