.PrimaryButton {
  color: #fff;
  border: none;
  box-shadow: none;
  background-color: var(--primary-color);

  &:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    background-color: var(--primary-color-hover);
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    color: #fff;
    background-color: var(--primary-color-dark);
  }

  &:disabled {
    color: var(--button-disabled-color);
    background-color: var(--cell-disabled-background);
  }

  /**/
  &--XS {
    font-size: 12px !important;
    line-height: 18px;
    padding: 3px 10px !important;
    height: 24px;
    max-height: 24px;
    min-height: 24px;
  }

  /**/
  &--S {
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px !important;
    height: 32px;
    max-height: 32px;
    min-height: 32px;
  }

  /**/
  &--M {
    font-size: 14px !important;
    line-height: 20px;
    padding: 10px 16px !important;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
  }

  /**/
  &--L {
    font-size: 16px;
    line-height: 26px;
    padding: 11px 24px !important;
    height: 48px;
    max-height: 48px;
    min-height: 48px;
  }

  /**/
  &--XL {
    font-size: 18px !important;
    //line-height: 32px;
    padding: 16px 32px !important;
    height: 56px;
    max-height: 56px;
    min-height: 56px;
  }

  /**/
  .icon-custom {
    font-size: 133%;
    margin-top: 1px;

    &.icon-plus {
      margin-top: 0;
    }

    &.icon-upload {
      font-size: 100%;
    }
  }
}
