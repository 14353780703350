.ant-pagination.ant-table-pagination .ant-pagination-item,
.ant-pagination.ant-table-pagination .ant-pagination-next,
.ant-pagination.ant-table-pagination .ant-pagination-prev {
  height: 24px;
  line-height: 22px;
  margin: 0;
  min-width: 24px;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 400;

  &:hover {
    border-color: var(--primary-color-hover);

    a {
      color: var(--primary-color-hover);
    }
  }
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active) {
  color: var(--text);

  &:hover {
    background-color: var(--component-background);

    a {
      color: var(--primary-color);
      border-color: var(--component-background);
      background-color: var(--component-background);
    }
  }

  &:active {
    background-color: var(--component-background);

    a {
      color: var(--primary-color);
      border-color: var(--component-background);
      background-color: var(--component-background);
    }
  }
}
