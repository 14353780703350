html,
body {
  margin: 0;
  height: 100%;
  max-height: 100%;
}

body {
  font: 400 normal 16px/26px $font-inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: var(--sat) !important; /* iOS 11.2 */
  overflow: hidden;
  color: var(--text);
  background-color: var(--body-background);
}

@supports (-webkit-touch-callout: none) {
  body {
    width: 100vw;
  }
}

.Html--app {
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-ttl;
  word-break: break-word;
  color: var(--heading-color);
}
h1 {
  margin: 0;
}
h3 {
  margin-bottom: 8px;
}
h4 {
  margin-bottom: 4px;
}

aside,
main,
header,
footer {
  display: block;
}

header,
footer {
  overflow: unset;
  padding: 0;
}

figure {
  margin: 0 !important;
}

input {
  box-sizing: border-box;
}

p {
  margin-top: 0;
  margin-bottom: 12px;
}

a {
  cursor: pointer;
  transition: all 0.2s;
  color: var(--primary-color);
  text-decoration: none;

  @media (any-hover: hover) {
    &:hover {
      color: var(--primary-color-hover);
    }
  }

  &:focus-visible {
    color: var(--primary-color-hover);
  }

  &:active {
    color: var(--primary-color-dark);
  }
}
