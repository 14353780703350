.app {
  &-h1 {
    font: 700 32px/40px $font-ttl;
    text-transform: uppercase;
    margin-bottom: 24px;

    @include breakpoint(sm) {
      font: 700 28px/36px $font-ttl;
    }
  }

  &-h2 {
    font: 700 26px/36px $font-ttl;
    text-transform: uppercase;

    @include breakpoint(sm) {
      font: 700 26px/32px $font-ttl;
    }
  }

  &-h3 {
    font: 700 24px/32px $font-ttl;
    text-transform: uppercase;

    @include breakpoint(sm) {
      font: 700 24px/30px $font-ttl;
    }
  }

  &-h4 {
    font: 700 20px/26px $font-ttl;
    text-transform: uppercase;
  }

  &-h5 {
    font: 700 18px/24px $font-ttl;
    text-transform: uppercase;
  }

  &-h6 {
    font: 700 16px/22px $font-ttl;
    text-transform: uppercase;
  }

  /**/
  &-subtitle-1 {
    font: 700 16px/26px $font-inter;
  }
  &-subtitle-1--underline {
    font: 700 16px/26px $font-inter;
    text-decoration: underline;
  }

  &-subtitle-2 {
    font: 700 14px/20px $font-inter;
  }
  &-subtitle-2--underline {
    font: 700 14px/20px $font-inter;
    text-decoration: underline;
  }

  /**/
  &-body-1 {
    font: 400 16px/26px $font-inter;
    margin-bottom: 12px;
  }
  &-body-1--underline {
    font: 400 16px/26px $font-inter;
    margin-bottom: 12px;
    text-decoration: underline;
  }

  &-body-2 {
    font: 400 14px/20px $font-inter;
    margin-bottom: 8px;
  }
  &-body-2--underline {
    font: 400 14px/20px $font-inter;
    margin-bottom: 8px;
    text-decoration: underline;
  }

  /**/
  &-caption {
    font: 400 12px/18px $font-inter;
    margin-bottom: 4px;
  }
  &-caption-bold {
    font: 700 12px/18px $font-inter;
    margin-bottom: 4px;
  }
  &-caption--underline {
    font: 400 12px/18px $font-inter;
    margin-bottom: 4px;
    text-decoration: underline;
  }
  &-caption--italic {
    font: 400 italic 12px/18px $font-inter;
    margin-bottom: 4px;
  }

  /**/
  &-overline-1 {
    font: 400 14px/22px $font-inter;
    text-transform: uppercase;
  }
  &-overline-1--underline {
    font: 400 14px/22px $font-inter;
    text-transform: uppercase;
    text-decoration: underline;
  }

  &-overline-2 {
    font: 400 12px/18px $font-inter;
    text-transform: uppercase;
  }
  &-overline-2--bold {
    font: 700 12px/18px $font-inter;
    text-transform: uppercase;
  }
  &-overline-2--underline {
    font: 400 12px/18px $font-inter;
    text-transform: uppercase;
    text-decoration: underline;
  }
}
