.ant-color-picker-trigger {
  box-shadow: none;

  &:focus,
  &:hover {
    border-color: var(--primary-color-light) !important;
  }
}

.ant-color-picker-trigger-active {
  border-color: var(--primary-color-light) !important;

  &:focus,
  &:hover {
    border-color: var(--primary-color-light) !important;
  }
}
