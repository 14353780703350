.ant-steps-item.ant-steps-item-wait {
  .ant-steps-item-title {
    color: var(--button-disabled-color) !important;
  }
  .ant-steps-item-icon {
    background: var(--cell-disabled-background);

    .ant-steps-icon {
      color: var(--button-disabled-color) !important;
    }
  }
}

.ant-steps .ant-steps-item-title::after {
  background: var(--nav-icon-color) !important;
}
