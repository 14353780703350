.ant-tag .anticon {
  vertical-align: 1px;
}
.ant-tag.app-custom-tag {
  border: 1px solid var(--button-border-color);
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin-right: 0;
  min-width: 140px;
  padding: 0 24px;
  text-align: center;
}
.ant-tag .ant-tag-close-icon:not(:hover) {
  color: #979797 !important;
}
