.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-dropdown
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: var(--primary-color-light);
}

/**/
@media only screen and (max-width: 576px) {
  .ant-picker-time-panel {
    width: 104px !important;
  }

  .ant-picker-time-panel-cell-inner {
    width: 43px !important;
    padding-inline-start: 13px !important;
  }

  .ant-picker-dropdown .ant-picker-time-panel-column {
    width: 49px;
  }

  .ant-picker-date-panel {
    width: 246px !important;

    .ant-picker-body {
      padding: 8px !important;
    }

    .ant-picker-content {
      width: 230px !important;
    }

    .ant-picker-content th {
      height: unset;
      width: 30px !important;
      vertical-align: top;
    }
  }

  .ant-picker-cell.ant-picker-cell-in-view {
    padding: 5px 0 !important;
  }

  .ant-picker-dropdown .ant-picker-time-panel .ant-picker-content {
    height: 208px !important;
  }

  .ant-picker-dropdown .ant-picker-header > button {
    width: 34px;
  }
}

.ant-picker {
  .ant-picker-clear .anticon,
  .ant-picker-suffix .anticon,
  .ant-picker-separator .anticon {
    color: #979797 !important;
  }

  .ant-picker-clear {
    background-color: var(--component-background);
  }
}
