.ant-carousel {
  color: var(--text);
}

.ant-carousel .slick-dots li button {
  background: var(--component-background);
}
.ant-carousel .slick-dots li.slick-active button {
  background: var(--component-background);
}
