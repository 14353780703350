.AppButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:not(:focus-visible) {
    outline: none;
  }

  /**/
  /**/
  //&--XS {
  //  font-size: 12px !important;
  //  line-height: 18px;
  //  height: 24px;
  //  max-height: 24px;
  //  min-height: 24px;
  //}

  /**/
  /**/
  //&--S {
  //  font-size: 14px;
  //  line-height: 20px;
  //  height: 32px;
  //  max-height: 32px;
  //  min-height: 32px;
  //}

  /**/
  /**/
  //&--M {
  //  font-size: 14px !important;
  //  line-height: 20px;
  //  height: 40px;
  //  max-height: 40px;
  //  min-height: 40px;
  //}

  /**/
  /**/
  //&--L {
  //  font-size: 16px;
  //  line-height: 26px;
  //  height: 48px;
  //  max-height: 48px;
  //  min-height: 48px;
  //}

  /**/
  /**/
  //&--padding {
  //  &.AppButton--XS {
  //    padding: 3px 10px !important;
  //  }
  //
  //  &.AppButton--S {
  //    padding: 6px 12px !important;
  //  }
  //
  //  &.AppButton--M {
  //    padding: 10px 16px !important;
  //  }
  //
  //  &.AppButton--L {
  //    padding: 11px 24px !important;
  //  }
  //}
}
