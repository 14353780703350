.quill {
  border: 1px solid var(--button-border-color);
  border-radius: var(--border-radius-base);
  @include transition;

  &:hover {
    border-color: var(--primary-color);
  }
}

.ql-toolbar {
  background-color: var(--input-toolbar);
  border-color: var(--button-border-color);
}

.ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #fefcfc;
  font-size: 16px;
  font-family: $font-inter;
}

.ql-tooltip.ql-editing {
  left: 0 !important;
}

/* Snow Theme */
.ql-snow.ql-toolbar {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  line-height: initial;
  border-color: var(--button-border-color);
  border-width: 0 0 1px;
}

.ql-container.ql-snow {
  border: 0;
  background-color: $white;
}

/* Bubble Theme */
.ql-bubble .ql-editor {
  border: 1px solid #ccc;
}

.ql-editor {
  min-height: 18em;
  padding-left: 11px;
  padding-right: 11px;
  background-color: var(--component-background);
  border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);

  .Body--touch:not(.Body--ios) & {
    padding-top: 52px;
  }
}

.ql-editor.ql-blank::before {
  font-style: normal;
  color: $grey-medium;
}

.ql-snow .ql-tooltip.ql-editing input[type='text'] {
  line-height: 26px;

  @include breakpoint(sm) {
    display: block;
  }
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: var(--text);
}

.ql-snow .ql-stroke {
  stroke: var(--text);
}

.ql-snow .ql-picker {
  color: var(--text);
}

.ql-picker-label:focus,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background-color: var(--input-toolbar);
  border-color: var(--button-border-color);
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin-bottom: 16px !important;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote {
  margin-bottom: 12px !important;
}

.ant-form-item-control {
  &.has-error {
    .quill {
      border-color: $error;

      &:focus {
        box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
      }
    }
  }
}

/*
.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-color) !important;
}
*/
