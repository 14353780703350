.ant-popover-inner {
  padding: 0 !important;
  //background-color: var(--component-background);
}

//.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active,
//.ant-zoom-big-fast-leave {
//  //animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
//  //animation-timing-function: unset !important;
//  animation-duration: 0ms !important;
//}

//.ant-zoom-big-fast-enter,
//.ant-zoom-big-fast-appear {
//  //transform:scale(0);
//  //opacity:0;
//  //animation-timing-function:cubic-bezier(0.08, 0.82, 0.17, 1);
//  animation-timing-function: unset !important;
//}
//
//.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
//.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
//  animation-name: none !important;
//  animation-play-state: unset !important;
//}
//
//:where(.css-dev-only-do-not-override-xh5psf).ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
//  animation-name: none !important;
//  animation-play-state: unset !important;
//  pointer-events:none;
//}

//.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
//  animation-name: fadeIn !important;
//}
